import React, { useEffect, useRef, useState } from "react";

import { animate } from "motion";

import { Box, type BoxProps } from "~components/Box";

import { getAnimationSliderItemID } from "~animations/animation_primitives/AnimationSlider/AnimationSliderItem";

import * as mockUiCommonStyles from "../../../styles/mockUiCommonStyles.css";

export interface DialogMockupProps extends BoxProps {
  children?: React.ReactNode;
  shouldOpen: boolean;
  currentStep?: number;
}

const OVERLAY_DIALOG_ID = "overlay-dialog";

export function DialogMockup({
  children,
  shouldOpen,
  currentStep,
}: DialogMockupProps) {
  const [childrenStep, setChildrenStep] = useState(currentStep);
  const [isOpen, setIsOpen] = useState(false);
  const overlayDialogRef = useRef(null);
  const [childrenHeight, setChildrenHeight] = useState("0");

  if (shouldOpen && !isOpen) {
    setIsOpen(true);
  } else if (!shouldOpen && isOpen) {
    setIsOpen(false);
  }

  /**
   * Check children step change when dialog is already opened
   */
  useEffect(() => {
    const isChangingStep = shouldOpen && isOpen && currentStep !== childrenStep;
    if (isChangingStep) {
      setChildrenStep(currentStep);
    }
  }, [shouldOpen, isOpen, currentStep, childrenStep]);

  /**
   * Animate dialog opening
   */
  useEffect(() => {
    if (!overlayDialogRef.current) return;
    if (!isOpen) return;

    animate(
      overlayDialogRef.current,
      { y: ["100%", 0], opacity: [1, 1], height: ["auto", "auto"] },
      { duration: 1 }
    );
  }, [isOpen]);

  /**
   * Animate dialog resize when changing step
   */
  useEffect(() => {
    const childrenID = getAnimationSliderItemID(childrenStep);
    const childrenElement = document.getElementById(childrenID);
    const newChildrenHeight = `${childrenElement?.clientHeight}px`;

    if (
      overlayDialogRef.current &&
      childrenStep &&
      newChildrenHeight !== childrenHeight
    ) {
      animate(
        overlayDialogRef.current,
        { height: [childrenHeight, newChildrenHeight] },
        { duration: 1 }
      );

      setChildrenHeight(newChildrenHeight);
    }
  }, [childrenStep, childrenHeight]);

  return (
    <Box
      display={shouldOpen ? "flex" : "none"}
      flexDirection="column"
      position="absolute"
      inset="0"
      id="dialog-mockup"
    >
      <Box id="overlay-bg" className={mockUiCommonStyles.overlayBg} />
      <Box
        id={OVERLAY_DIALOG_ID}
        ref={overlayDialogRef}
        marginTop="auto"
        className={mockUiCommonStyles.overlayDialog}
        borderTopLeftRadius="md"
        borderTopRightRadius="md"
        zIndex="2"
      >
        {children}
      </Box>
    </Box>
  );
}
