import React, { forwardRef } from "react";

import clsx from "clsx";

import { vars } from "~styles/themes/theme.css";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ThemeContext } from "~sections/LayoutSection";

import { Box } from "../Box";
import * as styles from "./styles.css";

import type { BoxProps } from "../Box";
import type { ReactNode, Ref } from "react";

export interface PhoneMockupProps extends BoxProps {
  children?: ReactNode;
  className?: string;
}
export const PhoneMockup = forwardRef(
  (
    {
      children,
      maxWidth = "gridSpan3",
      className: userClassName,
      width = "100%",
      ...rest
    }: PhoneMockupProps,
    ref: Ref<HTMLElement>
  ) => {
    return (
      <ThemeContext.Provider value="background_mockUiCard">
        <Box
          data-testid="phoneMockup-container"
          ref={ref}
          maxWidth={maxWidth}
          borderRadius="phone"
          __border={`${vars.spacing.spacing0} solid ${vars.color.neutral.black}`}
          overflow="hidden"
          position="relative"
          className={clsx(
            userClassName,
            variantBackgroundColor.background_white,
            styles.phoneMockup
          )}
          width={width}
          aspectRatio="mobile"
          {...rest}
        >
          {children}
        </Box>
      </ThemeContext.Provider>
    );
  }
);
